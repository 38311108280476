import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable'; // Librería para generar tablas estilizadas
import './InvoiceForm.css'; // Importamos los estilos externos
import logo from './logo.png'; // Ruta del logo
import borde1 from './borde1.png'; // Ruta del borde superior izquierdo
import borde2 from './borde2.png'; // Ruta del borde medio izquierdo
import borde3 from './borde3.png'; // Ruta del borde inferior izquierdo
import borde4 from './borde4.png'; // Ruta del borde superior
import { montserratRegularBase64, montserratBlackBase64, montserratExtraBoldBase64, } from './fonts'; // Ajusta la ruta según tu proyecto
import { locationIconBase64, phoneIconBase64 } from './icons';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  colors,
  Grid, 
  Typography,
  Button,
  Box,
  Divider,
} from "@mui/material"
import {  } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
  },
});



function InvoiceForm() {
  
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [taxes, setTaxes] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [invoiceItems, setInvoiceItems] = useState([{ closingDate: '', borrowerName: '', la: '', unitP: '', total: '' }]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);

  const validateFields = () => {
    const fields = [];
    let errorMessage = 'Todos los campos son obligatorios.';
  
    // Validar número de factura y fecha de factura
    if (!invoiceNumber) {
      fields.push('Número de factura');
      errorMessage += ' Número de factura .';
    }
    if (!invoiceDate) {
      fields.push('Fecha de factura');
      errorMessage += ' Fecha de factura.';
    }
  
    // Validar los ítems de la tabla
    invoiceItems.forEach((item, index) => {
      const itemErrors = [];
  
      if (!item.closingDate) itemErrors.push('File Closing Date');
      if (!item.borrowerName) itemErrors.push('Borrower Name');
      if (!item.la) itemErrors.push('LA');
      if (!item.unitP) itemErrors.push('Unit P');
      if (!item.total) itemErrors.push('Total');
  
      if (itemErrors.length > 0) {
        fields.push(`Ítem ${index + 1}: ${itemErrors.join(', ')}`);
        errorMessage += ` Para el ítem ${index + 1}, falta rellenar los campos: ${itemErrors.join(', ')}.`;
      }
    });
  
    setEmptyFields(fields);
    setErrorMessage(errorMessage);
    return fields; // Ahora retorna los campos vacíos
  };
  
  
  const removeItem = (index) => {
    const newItems = [...invoiceItems];
    newItems.splice(index, 1); // Elimina el ítem en el índice correspondiente
    setInvoiceItems(newItems);
  };
  const formatCurrency = (value) => {
    return `$${parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };
      

  const handleInputChange = (index, event) => {
    const values = [...invoiceItems];
    values[index][event.target.name] = event.target.value;
    setInvoiceItems(values);
  };

  const handleAddItem = () => {
    for (let i = 0; i < invoiceItems.length; i++) {
      const { closingDate, borrowerName, la, unitP, total } = invoiceItems[i];
      if (!closingDate || !borrowerName || !la || !unitP || !total) {
        setErrorMessage('Todos los campos son obligatorios. Para el ítem 1 falta rellenar los campos: File Closing Date, Borrower Name.');
        return;
      }
    }
    setErrorMessage('');
    setInvoiceItems([...invoiceItems, { closingDate: '', borrowerName: '', la: '', unitP: '', total: '' }]);
  };

    const handleInvoiceNumberChange = (e) => setInvoiceNumber(e.target.value);
    const handleInvoiceDateChange = (e) => setInvoiceDate(e.target.value);
    const formatDateUS = (date) => {
        const [year, month, day] = date.split('-');
        return `${month}/${day}/${year}`;
      };
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const Modal = ({ isOpen, closeModal, fields }) => {
        if (!isOpen) return null;
      
        return (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Campos Vacíos</h2>
              <p>No se puede enviar porque los siguientes campos están vacíos:</p>
              <ul>
                {fields.map((field, index) => (
                  <li key={index}>{field}</li>
                ))}
              </ul>
              <button onClick={closeModal}>Cerrar</button>
            </div>
          </div>
        );
      };

  useEffect(() => {
    const newSubtotal = invoiceItems.reduce((acc, item) => acc + parseFloat(item.total || 0), 0);
    setSubtotal(newSubtotal);

    const newTotal = newSubtotal + (newSubtotal * taxes / 100);
    setTotal(newTotal);
  }, [invoiceItems, taxes]);
  
  useEffect(() => {
    if (emptyFields.length > 0) {
      validateFields();
    }
  }, [invoiceItems]);

  // Función para exportar a PDF con estilo
  const generatePDF = () => {
    const fields = validateFields();
        if (fields.length > 0) {
            setEmptyFields(fields);
            setIsModalOpen(true);
            return; // No genera el PDF si hay campos vacíos
        }
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const borde4Width = 150; // Ancho de la imagen borde4.png (ajusta este valor según el tamaño real)
      // Definir el margen derecho
    const rightMargin = 10; // Añade esta línea para definir rightMargin
     // Obtener los valores del formulario
    const invoiceNumber = document.getElementById('invoiceNumber').value; // ID del input del número de factura
    const invoiceDate = document.getElementById('invoiceDate').value; // ID del input de la fecha de factura
     // Dimensiones del rectángulo
    const rectX = 160; // Posición X del rectángulo
    const rectY = 20; // Posición Y del rectángulo
    const rectWidth = 40; // Ancho del rectángulo
    
    
    // Registrar Montserrat-Regular
    doc.addFileToVFS("Montserrat-Regular.ttf", montserratRegularBase64);
    doc.addFont("Montserrat-Regular.ttf", "Montserrat-Regular", "normal");

    // Registrar Montserrat-Black
    doc.addFileToVFS("Montserrat-Black.ttf", montserratBlackBase64);
    doc.addFont("Montserrat-Black.ttf", "Montserrat-Black", "normal");

    // Registrar Montserrat-ExtraBold
    doc.addFileToVFS("Montserrat-ExtraBold.ttf", montserratExtraBoldBase64);
    doc.addFont("Montserrat-ExtraBold.ttf", "Montserrat-ExtraBold", "normal");


    // Logo
    doc.addImage(logo, 'PNG', 20, 15, 15, 15);
    // Añadir el texto "RICKROCK"
    doc.setFont('Montserrat-ExtraBold'); // Asegúrate de que Montserrat-Black esté registrado
    doc.setFontSize(25);
    doc.setTextColor('#000000'); // Color negro
    doc.text('RICKROCK', 35, 23); // Ajusta la posición para que esté al lado del logo
    // Añadir el texto "Investment LLC"
    doc.setFont('Montserrat-Regular'); // Cambiar a Montserrat Regular
    doc.setFontSize(10);
    doc.setTextColor('#676868'); // Color gris
    doc.text('Investment LLC', 36, 27); // Ajusta la posición debajo de "RICKROCK"

    // Información en la parte superior derecha
    doc.setFont("Montserrat-Regular");
    doc.setFontSize(8);
    doc.setTextColor('#676868');
    doc.addImage(locationIconBase64, 'PNG', pageWidth - 45 - rightMargin, 15, 3, 4);
    doc.text('461 SW 176TH AVE', pageWidth - 40 - rightMargin, 17); // Texto de dirección
    doc.text('PEMBROKE PINES, FL, 33029', pageWidth - 40 - rightMargin, 20);

    doc.addImage(phoneIconBase64, 'PNG', pageWidth - 45 - rightMargin, 25, 4, 3); // Icono de teléfono
    doc.text('786-328-7448', pageWidth - 40 - rightMargin, 27); // Texto de teléfono

    // Sección de "Bill To"
    doc.setFont("Montserrat-Regular");
    doc.setTextColor('#000000');
    doc.setFontSize(10);
    doc.text('Bill To :', 20, 45);
    doc.setFont("Montserrat-ExtraBold");
    doc.text('RBI MORTGAGES LLC', 20, 50);
    doc.text('19495 Biscayne Blvd, Suite 801', 20, 55);
    doc.text('Miami, FL, 33180', 20, 60);
    doc.text('754-210-6312', 20, 65);

     // Dibujar el recuadro con borde redondeado
    doc.setFillColor('#a34523');
    doc.setDrawColor('#a34523');
    doc.setFont("Montserrat-ExtraBold");
    doc.setFontSize(16);
    doc.setTextColor('#ffffff');
    doc.rect(pageWidth - 95, 45, 75, 20, 'F');  // Aquí mueves todo el cuadro hacia abajo
    doc.text('INVOICE', pageWidth - 90, 57); 
    doc.setFontSize(12);   // Mueve el texto "Invoice" más abajo
    doc.text('No.', pageWidth - 60, 53);
    doc.text(invoiceNumber, pageWidth - 51, 53);  // Ajusta el número de factura
    doc.text(formatDateUS(invoiceDate), pageWidth - 60, 60);   // Ajusta la fecha
    // "Invoice" seccion
    // Texto "Invoice"
    doc.setFont('Montserrat-Black');
    doc.setFontSize(10);
    doc.setTextColor('#ffffff');
    doc.text('Invoice', rectX + 5, rectY + 10); // Texto del título
    // ** Añadir Número de Factura y Fecha dentro del recuadro **
    doc.setFont('Montserrat-Regular');
    doc.setFontSize(8);
    doc.setTextColor('#ffffff'); // Texto blanco

    // Número de Factura
    doc.text(invoiceNumber, rectX + rectWidth - 20, rectY + 5); // Ajusta la posición según necesidad

    // Fecha de Factura
    doc.text(invoiceDate, rectX + rectWidth - 20, rectY + 10); // Ajusta la posición según necesidad



    // Añadir los artículos de la factura
    const itemRows = invoiceItems.map((item) => [
      formatDateUS(item.closingDate || ''),
      item.borrowerName || '',
      formatCurrency(item.la || 0),
      formatCurrency(item.unitP || 0),
      formatCurrency(item.total || 0),
    ]);

    // Tabla de los Items
    doc.autoTable({
      margin: { left: 21 },
        styles: {
            font: 'Montserrat-Regular', // O la fuente que hayas agregado
            fontSize: 10,  // Ajusta el tamaño aquí
          },
         /* headStyles: {
            fillColor: [213, 137, 53], // O el color que desees
            textColor: [0, 0, 0],  // Blanco, por ejemplo
            fontSize: 10, // Ajusta el tamaño de la fuente para el encabezado
            font: 'Montserrat-Black', // Para hacerlo negrita
          },*/
      startY: 70,
      head: [['File Closing Date', 'Borrower Name', 'LA (USD)', 'UnitP (USD)', 'Total (USD)']],
      body: itemRows,
      theme: 'grid',
      headStyles: { 
        fillColor: [214, 149, 64], 
        textColor: [0, 0, 0],
        font: 'Montserrat-ExtraBold',
        fontSize: 11,

      },
      columnStyles: {
        0: { cellWidth: 30 } // Ajusta el ancho de la primera columna (índice 0) a 80 unidades
      },
      styles: { fontSize: 10 },
    });

    // Añadir subtotal, impuestos y total
    const finalY = doc.lastAutoTable.finalY;
    doc.setFont('Montserrat-ExtraBold');
    doc.setFontSize(12);
    doc.setTextColor('#000000');
    doc.text(`Subtotal: ${formatCurrency(subtotal)}`, 20, finalY + 10);
    doc.text(`Taxes: ${taxes}%`, 20, finalY + 20);
    doc.setFillColor('#f2f2b0');
    doc.rect(20, finalY + 30, pageWidth - 40, 10, 'F');

    doc.text(`Balance Due: ${formatCurrency(total)}`, 20, finalY + 37);

    // Borde superior (colocar las imágenes)

    doc.addImage(borde4, 'PNG', pageWidth - 195, 0, 195, 10);

    // Borde izquierdo (colocar las imágenes)
    doc.addImage(borde1, 'PNG', 0, 0, 20, 60); // Borde superior izquierdo
    doc.addImage(borde2, 'PNG', 0, 48, 10, 103); // Borde medio izquierdo
    doc.addImage(borde3, 'PNG', 0, 135, 20, 165); // Borde inferior izquierdo

    const formattedDate = formatDateUS(invoiceDate).replace(/\//g, '-'); // Cambia el formato a MM-DD-YYYY
    const fileName = `Invoice_${formattedDate}_number${invoiceNumber}`;

    // Guardar PDF
    doc.save(`${fileName}.pdf`);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="invoice-form-container desktop-only desktop-form">
        <div>
          <h3>Invoice Info</h3>
          <label className="custom-label">
            Ingresar Número de Factura:
            <input
              type="number"
              id="invoiceNumber"
              value={invoiceNumber}
              onChange={handleInvoiceNumberChange}
              className="custom-input invoice-input"
              error={emptyFields.some(e => e.field === 'invoiceNumber')}
                helperText={emptyFields.some(e => e.field === 'invoiceNumber') ? 'Número de factura' : ''}
            />
          </label>
          <br />
          <label className="custom-label">
            Ingresar Fecha de Factura:
            <input
              type="date"
              id="invoiceDate"
              value={invoiceDate}
              className="custom-input invoice-input input-date"
              onChange={handleInvoiceDateChange}
              fontFamily="Montserrat-Regular"
            />
          </label>
        </div>

        <h3>Ingresar items a facturar </h3>
        <TableContainer component={Paper}>
          <Table className="invoice-table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '120px' }}>File Closing Date</TableCell>
                <TableCell sx={{ width: '400px' }}>Borrower's Name</TableCell> {/* Columna más ancha */}
                <TableCell sx={{ width: '95px' }}>LA (USD)</TableCell>
                <TableCell sx={{ width: '95px' }}>Unit P (USD)</TableCell>
                <TableCell sx={{ width: '95px' }}>TOTAL (USD)</TableCell>
                <TableCell sx={{ width: '150px' }}></TableCell> {/* Para el botón "Remove this item" */}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceItems.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      type="date"
                      name="closingDate"
                      value={item.closingDate}
                      onChange={(event) => handleInputChange(index, event)}
                      variant="outlined"
                      size="small"
                      className="input-date"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      name="borrowerName"
                      placeholder="Borrower's Name"
                      value={item.borrowerName}
                      onChange={(event) => handleInputChange(index, event)}
                      variant="outlined"
                      fullWidth
                      size="small"
                      className="input-borrower-name"
                      
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      name="la"
                      placeholder="LA"
                      value={item.la}
                      onChange={(event) => handleInputChange(index, event)}
                      variant="outlined"
                      className="input-number" 
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      name="unitP"
                      placeholder="Unit P"
                      value={item.unitP}
                      onChange={(event) => handleInputChange(index, event)}
                      variant="outlined"
                      className="input-number" 
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      name="total"
                      placeholder="Total"
                      value={item.total}
                      onChange={(event) => handleInputChange(index, event)}
                      variant="outlined"
                      className="input-number" 
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Button 
                      variant="outlined" 
                      color="error" 
                      onClick={() => removeItem(index)}
                    >
                      Remove this item
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {errorMessage && (
          <p style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</p>
        )}

        <Button variant="outlined" onClick={handleAddItem}>Add another item</Button>
        

        <h3 className="title-invoice">Resumen de Factura</h3>
        <div className="invoice-summary">
          <label className="custom-label">
            Subtotal: <strong>${subtotal.toFixed(2)}</strong>
          </label>
          <br />
          <label>
            Taxes (%):
            <input
              type="number"
              value={taxes}
              className="custom-input input-taxes"
              onChange={(e) => setTaxes(parseFloat(e.target.value))}
              placeholder="Taxes %"
            />
          </label>
          <br />
          <label className="custom-label">
            Balance Due: <strong>${total.toFixed(2)}</strong>
          </label>
          <div>
          <button onClick={generatePDF}>Exportar a PDF</button>
          </div>
        </div>
      </div>
      {/*FORMULARIO MOVIL*/}
      <div className="mobile-form">
        <Box sx={{ padding: '1rem' }}>
          <Typography variant="h4" align="center" gutterBottom>
            Invoice Info
          </Typography>

          {/* Invoice Number and Date */}
          <Box sx={{ marginBottom: '1rem' }}>
            <TextField
              fullWidth
              label="Invoice Number"
              type="text"
              name="invoiceNumber"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
            
            <TextField
              fullWidth
              label="Invoice Date"
              type="date"
              name="invoiceDate"
              value={invoiceDate}
              onChange={(e) => setInvoiceDate(e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
          </Box>

          {/* Invoice Items Section */}
          <Typography variant="h5" gutterBottom>
            Items:
          </Typography>

          {invoiceItems.map((item, index) => (
            <Box key={index} sx={{ marginBottom: '2rem', border: '1px solid #ddd', padding: '1rem' }}>
              <Typography variant="h6">Item {index + 1}</Typography>

              <TextField
                fullWidth
                label="File Closing Date"
                type="date"
                name="closingDate"
                value={item.closingDate}
                onChange={(event) => handleInputChange(index, event)}
                sx={{ marginBottom: '1rem' }}
              />

              <TextField
                fullWidth
                label="Borrower's Name"
                type="text"
                name="borrowerName"
                value={item.borrowerName}
                onChange={(event) => handleInputChange(index, event)}
                sx={{ marginBottom: '1rem' }}
              />

              <TextField
                fullWidth
                label="LA (USD)"
                type="number"
                name="la"
                value={item.la}
                onChange={(event) => handleInputChange(index, event)}
                sx={{ marginBottom: '1rem' }}
              />

              <TextField
                fullWidth
                label="Unit P (USD)"
                type="number"
                name="unitP"
                value={item.unitP}
                onChange={(event) => handleInputChange(index, event)}
                sx={{ marginBottom: '1rem' }}
              />

              <TextField
                fullWidth
                label="Total (USD)"
                type="number"
                name="total"
                value={item.total}
                onChange={(event) => handleInputChange(index, event)}
                sx={{ marginBottom: '1rem' }}
              />

              <Button variant="outlined" color="error" onClick={() => removeItem(index)}>
                Remove Item
              </Button>

              <Divider sx={{ marginTop: '1rem' }} />
            </Box>
          ))}

          {/* Add Item Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddItem}
            sx={{ marginTop: '1rem', marginBottom: '2rem' }}
          >
            Add Item
          </Button>

          {/* Subtotal, Taxes, Total */}
          <Box sx={{ marginBottom: '2rem' }}>
            <Typography variant="body1">
              <strong>Subtotal:</strong> {formatCurrency(subtotal)}
            </Typography>
            <TextField
              fullWidth
              label="Taxes"
              type="number"
              value={taxes}
              onChange={(e) => setTaxes(e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
            <Typography variant="body1">
              <strong>Total:</strong> {formatCurrency(total)}
            </Typography>
          </Box>

          {/* PDF Export Button */}
          <Button
            variant="contained"
            color="secondary"
            onClick={generatePDF}
            fullWidth
            sx={{ marginTop: '2rem' }}
          >
            Export to PDF
          </Button>
        </Box>
      </div>


        {/* Botón para exportar el PDF */}
        
        <Modal isOpen={isModalOpen} closeModal={closeModal} fields={emptyFields} />
      
    
    </ThemeProvider>
  );
}

export default InvoiceForm;
